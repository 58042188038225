export function decryptToken(token) {
  try {
    const base64 = Buffer.from(token.replace(/-/g, '+').replace(/_/g, '/'), 'base64').toString();
    const decoded = new Uint8Array(base64.length);
    const key = process.env.VUE_APP_INVOICE_KEY;
    // console.log('KEY: ', key);
    for (let i = 0; i < base64.length; i++) {
      decoded[i] = base64.charCodeAt(i);
    }
    let result = '';
    for (let i = 0; i < decoded.length; i++) {
      result += String.fromCharCode(decoded[i] ^ key.charCodeAt(i % key.length));
    }
    return result;
  } catch (e) {
    console.log('ERROR DECRYPT TOKEN: ', e);
  }
}
